
.main{
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  z-index: 1;

  background: #272727;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

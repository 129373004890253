@import '@globalStyles/global.module.scss';

.container {
	width: 114px;

	display: flex;
	justify-content: space-between;

	position: absolute;
	right: $mr;
	top: $margin-menu;
	z-index: 1000;

	&.threeIcons {
		width: 246px;
	}
}

.pdf {
	width: max-content;
	height: 90%;

	margin: calc($margin-menu + 20px) auto;

	overflow-y: auto;
	border-radius: 10px;
	border: 1px solid #858585;

	background: rgba(63, 63, 63, 0.3);
}

.pdf::-webkit-scrollbar {
	width: 6px;
}

.pdf::-webkit-scrollbar-thumb {
	background-color:  #ccc;
}

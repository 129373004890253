.contactUs{
  width: 98%;
  height: 28px;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;

  position: absolute;
  top: 93%;
  z-index: 3;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;


  color: #CDCDCD;

  padding-right: 1%;
  padding-left: 1%;
}

.btnRight{
  width: 33%;
  height: 28px;

  display: flex;
  justify-content: flex-start;
}

.btnCenter{
  width: 33%;
  height: 28px;

  position: relative;

  display: flex;
  justify-content: center;

  cursor: pointer;
}

.btnLeft{
  width:33%;
  height: 28px;

  display: flex;
  justify-content: flex-end;
}

.btnText{
  width: max-content;

  cursor: pointer;
}

.contactUSLabel{
  position: absolute;
  z-index: 2;
  left: 50%;
  bottom: 0;

  transform: translate(-50%, 0);

  font-family: Inter;
  font-size: 18px;
  line-height: 18px;

  background: red;

  cursor: pointer;
}

.container{
  width: 98%;

  position: relative;
  margin-bottom: 0;
  z-index: 10;

  display: flex;
  justify-content: space-around;


  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  //line-height: 28px;

  color: #000000;
}

.company{
  font-family: 'Inter';
  font-size: 18px;

  cursor: pointer;
}

.language{
  line-height: 18px;

  font-family: 'Inter';
  font-size: 18px;

  cursor: pointer;
}

.iconContainer{
  width: 80px;

  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0);



  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;

  padding-top: 4px;
}

@media (max-width: 820px) {
  .btnCenter{
    font-size: 10px;
    line-height: 18px;
  }

  .btnLeft{
    //width: 80px;

    line-height: 18px;
    text-align: right;

    font-size: 10px;
  }

  .btnRight{
    font-size: 10px;
  }
}
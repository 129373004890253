

.iconContainer{
  width: 80px;

  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;

  padding-top: 4px;
}

.icon{
  color: #00C2FD;
  background-color: none;
}


@import '../../../../globalStyles/global.module.scss';

.modalContainer {
  width: 30%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 20px;
  padding: 18px 24px;
  border-radius: $standard-border-radius;
  border: 1px solid #858585;

  font-size: 20px;

  background-color: $background-items;
  color: #ccc;
}
.contentBox{
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 10px 0;
}


.labelAndBox{
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 5px 0;
}

.conteiner{
  display: flex;
}

.inputBox{
  width: 50px;
  height: calc(40px - 20px);

  padding: 5px;

  margin: 0 10px;

  display: flex;

  align-items: center;

  border: 1px solid #858585;
  border-radius: $standard-border-radius;

  color: #cfcfcf;

  font-size: 20px;

  &:hover {
    border: 1px solid #00C2FD;
  }

  &:focus-within {
    border: 1px solid #00C2FD;
  }
}

.styleInput{
  width: 100%;

  border: none;

  background: inherit;
  color: #cfcfcf;

  font-size: 18px;

  &:focus {
    outline: none;
  }
}

.setInput {
  display: flex;
  justify-content: space-between;
}

.boxStyle{
  width: 100%;
}

.listContent{
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 0 !important;
}

.inputList{
  width: 100%;
  height: calc(100% - 70px);
}

.boxList{
  width: 100%;
  height: 50%;
}

.header{
  width: 100%;
  height: 30px;

  position: relative;

  display: flex;
  justify-content: center;

  font-size: 20px;
}

.labelAndInput {
  width: 99%;

  display: flex;
  justify-content: space-between;
  align-items: center;

}

.titleInfo {
  font-size: 20px;
  user-select: none;
}

.error {
  display: flex;

  font-size: 15px;

  color: red;
}

.errorTitle{
  width: calc(50% - 20px);

  font-size: 5px;
}


.iconButton{
  position: absolute;
  right: 0;

  display: flex;
  align-items: flex-end;

  cursor: pointer;

  &:hover {
    svg path {
      fill: #00C2FD;
    }

    svg circle {
      stroke: #00C2FD;
    }
  }
}

audio {
  width: 100%;
  height: 40px;

  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 2px;
}

audio::-webkit-media-controls-play-button {
  font-size: 20px;

  background-color: transparent;
  color: #333;
}

audio::-webkit-media-controls-timeline {
  background-color: #f1f1f1;
}

audio::-webkit-media-controls-timeline-progress-bar {
  background-color: #333;
}

audio::-webkit-media-controls-volume-slider {
  background-color: #f1f1f1;
}

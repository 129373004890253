$margin-menu: 26px;

//Постраничные отступы от родительского элемента
$mt: 88px;
$mr: 34px;
$mb: 88px;
$ml: 117px;

$standard-border-radius: 10px;

$background-items: rgb(31, 31, 31);
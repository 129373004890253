@import '../../../globalStyles/global.module.scss';

.brick {
  height: 50px;
  width: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: $standard-border-radius;
  border: 1px solid #858585;

  cursor: pointer;

  position: relative;

  background-color: $background-items;
}
.navLinkWatch {
  width: 50%;

  display: flex;
  justify-content: flex-end;
  text-align: end;

  font-family: Inter, serif;
  font-size: 18px;

  text-decoration: none;

  cursor: pointer;

  color: #888888;

  &:hover {
    color: #00C2FD !important;
  }
}
.notificationModal{
  top: 90px;
  border: none;
}

.popoverStyle{
  min-width: 450px;
  width: 35%;
  height: 45vh;

  position: absolute;
  top: $mt;
  right: $mr;

  border-radius: $standard-border-radius;
  border: 1px solid #858585;

  overflow-y: auto;

  background-color: $background-items;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #D2D2D2;
  }
  z-index: 10000;
}

.listTitle{
  width: 100%;

  align-content: flex-start;
  font-family: 'Inter';
  font-size: 20px;

  user-select: none;

  color: #D2D2D2;
}
.list{
  width: 45vw;
  height: 30vh;

  border-radius: $standard-border-radius;
  border: 1px solid #858585;

  overflow-y: auto;

  background-color: $background-items;
}
.listItem{
  height: 60px;

  display: flex;
  justify-content: space-between;
}

.box{
  min-height: 150px;
  height: max-content;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tableText {
  width: 100%;
  height: 55px;

  display: flex;
  justify-content: space-between;
  align-content: center;
  gap: 10px;

  font-family: 'Inter';
  font-style: normal;
  font-size: 18px;
  line-height: 21px;

  color: #cdcdcd;
}

.listStyle{
  width: 100%;
  height: 65px;

  display: flex;
  flex-direction: column;
  align-content: center;

  color: #cdcdcd;
}

.row {
  width: calc(100% / 3);
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #cdcdcd;
}

.container {
  width: 100%;
  height: 45px;
}

.title{
  padding: 10px 0;


  display: flex;
  justify-content: left;

  font-family: 'Inter';
  font-size: 18px;
  color: #CDCDCD;
}

.btnContainer{
  height: 50px;

  margin-top: 10px;
  margin-bottom: 10px;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
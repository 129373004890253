@import '../../../../../globalStyles/global.module.scss';
.container {
  height: 100%;

  padding: 0 16px;

  font-size: 18px;
  color: #d2d2d2;
}
.boxStyle{
  width: 100%;
}

.listContent{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inputBox{
  width: 70px;
  height: calc(40px - 20px);

  padding: 10px 15px;

  margin: 0 10px;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  color: #cfcfcf;

  font-size: 20px;
}
.boxList{
  height: 50%;
}

.header{
  height: 30px;

  padding: 10px 0;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  font-size: 20px;
}
.labelAndInput {
  width: 99%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.errorTitle {
  width: 99%;
  height: 25px;

  display: none;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.error {
  display: flex;
  color: red;
  font-size: 15px;
}

.labelAndInput{
  width: 49%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.errorTitle{
  width: calc(50% - 20px);

  font-size: 5px;
}
.labelAndBox{
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  
  padding: 5px 0;
}

.styleInput{
  width: 100%;

  margin-left: -5px;

  border: none;

  background: inherit;
  color: #cfcfcf;

  font-size: 18px;

  &:focus {
    outline: none;
  }
}

.contentBox{
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 10px 0;
}

.iconButton{
  display: flex;
  align-items: flex-end;

  cursor: pointer;
}

audio {
  width: 100%;
  height: 40px;

  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 2px;
}

audio::-webkit-media-controls-play-button {
  background-color: transparent;
  color: #333;
  font-size: 20px;
}

audio::-webkit-media-controls-timeline {
  background-color: #f1f1f1;
}

audio::-webkit-media-controls-timeline-progress-bar {
  background-color: #333;
}

audio::-webkit-media-controls-volume-slider {
  background-color: #f1f1f1;
}

.modalButtons {
  width: 100%;
  height: 40px;

  display: flex;
  justify-content: space-between;

  .modalButton {
    width: 40%;
    height: 40px;

    font-size: 20px;

    border: none;
    border-radius: $standard-border-radius;

    color: #00c2fd;
    background: transparent;

    &:disabled, &:disabled:hover {
      border: none;

      color: rgba(108, 108, 108, 0.8);
      background: transparent;
    }

    &:hover:not(:disabled) {
      cursor: pointer;

      color: $background-items;
      background: #00C2FD;
    }
  }

  .modalCancelButton {
    width: 40%;
    height: 40px;

    font-size: 20px;
    border: none;

    border-radius: $standard-border-radius;
    cursor: pointer;

    color: #ccc;
    background: $background-items;

    &:hover {
      border: 2px solid  #ccc;
    }
  }
}

@import '../../../globalStyles/global.module.scss';

.rightCard {
  width: 24.9%;
  height: calc(100% - 30px);

  margin-left: 10px;
  padding: 15px 10px;

  border: 1px solid #858585;
  border-radius: $standard-border-radius;

  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 18px;

  background-color: $background-items;

  color: #CDCDCD;

  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #D2D2D2;
    border-radius: 3px;
  }
}

.tableRow {
  height: 50px;

  display: flex;
  justify-content: space-between;

  border-bottom: thin solid #6C6C6C;

  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.image {
  width: 100%;
  margin-top: 20px;
}
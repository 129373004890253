@import '../../../../globalStyles/global.module.scss';

.setIcons {
  margin-left: 13px;

  cursor: pointer;
}

.settings {
  position: absolute;
  z-index: 10;

  margin-left: -50px;

  color: #CDCDCD;

  border: 1px solid #858585;
  border-radius: $standard-border-radius;

  background-color: $background-items;

  p {
    margin: 0;
    padding: 10px;
    border: 1px solid transparent;

    &.activeType {
      border: 1px solid #00C2FD;
      border-radius: $standard-border-radius;

      color: #00C2FD;
    }
  }
}
@import '../../../globalStyles/global.module.scss';

.box{
  width: calc(50% - 10px);
  height: 98%;

  display: flex;
  flex-direction: column;

  border: 1px solid #858585;
  border-radius: $standard-border-radius;

  background-color: $background-items;
}
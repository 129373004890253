.modalContainer {
    width: 50%;
    max-height: 80%;

    padding: 20px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border: 1px solid #D2D2D2;
    border-radius: 10px;
    
    box-shadow: 24px;
    
    color: #D2D2D2;
    background-color: #272727;
}

.tableMain {
    width: 100%;

    border-radius: 10px;
    border: 1px solid #858585;
}

.title {
    width: 100%;
    height: 40px;

    position: relative;

    display: flex;
    justify-content: space-between;

    margin-bottom: 10px;
}

.downBut {
    display: flex;
    justify-content: space-between;
}

.excel{    
    padding: 10px;
    margin: 0 5px;

    border: 1px solid #D2D2D2;
    border-radius: 10px;

    font-size: 18px;
    color: #D2D2D2;

    cursor: pointer;

    background-color: #272727;

    &:hover{
        border: 1px solid #00C2FD;
        color: #00C2FD;
    }

    &:disabled{
        border: 1px solid hsl(0, 0%, 50%);
        color: hsl(0, 0%, 50%);
    }
}

.p-calendar {
    border: 1px solid #D2D2D2;
    border-radius: 10px;

    cursor: pointer;

    &:hover {
        border: 1px solid #00C2FD;

        &.p-calendar .p-inputtext {
            border-right:  1px solid #00C2FD;
            color: #00C2FD;
        }

        &.p-calendar-w-btn-right .p-datepicker-trigger {
            color: #00C2FD;
        }
    }
}

.p-calendar .p-inputtext {
    width: 50% !important;

    border-right:  1px solid #D2D2D2;

    text-align: center;
    font-size: 18px;
    
    color: #D2D2D2;
    cursor: pointer;
}

.p-datepicker * {
    font-size: 1rem;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}

.p-button-icon-only {
    margin-right: 10px;
}

.p-datepicker:not(.p-datepicker-inline) {    
    padding: 0.5rem;

    border: 1px solid #D2D2D2;
    border-radius: 10px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);

    color: #D2D2D2;
    background-color: #272727;

    z-index: 1500 !important;
}

.p-disabled, .p-component:disabled {
    opacity: 0.6;
}

.p-datepicker .p-datepicker-header {
    padding: 0.5rem;

    border-bottom: 1px solid var(--bgThemeBorder);
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
    width: 2rem;
    height: 2rem;

    border: 0 none;
    border-radius: 50%;

    color: #6c757d;
    background-color: var(--bgTheme);
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
    color: #00C2FD;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    padding: 0.5rem;
    color: var(--bgThemeTitle);
}

.p-datepicker table {
    margin: 0.5rem 0;
}

.p-datepicker table th,
.p-datepicker table td {
    padding: 0.5rem;
    background: #272727;
}

.p-datepicker table th > span,
.p-datepicker table td > span {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
}

.p-datepicker table td > span.p-highlight,
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    background: #00C2FD;;
}

.p-datepicker table td > span:focus {
    box-shadow: 0 0 0 0.2rem #00C2FD;
}

.p-datepicker table td.p-datepicker-today > span {
    border: 2px solid #00C2FD;
}

.p-datepicker .p-yearpicker,
.p-datepicker .p-monthpicker {
    margin: 0.5rem 0;
}

.p-datepicker .p-yearpicker .p-yearpicker-year,
.p-datepicker .p-monthpicker .p-monthpicker-month {
    padding: 0.5rem;
    border-radius: 3px;
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover,
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover,
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover{
    background: var(--bgButtonTextIcon);
}

.p-inputtext {
    padding: 0.5rem;

    border-radius: 3px;
    border: 1px solid var(--bgThemeBorder);
    color: var(--bgThemeTitle);
    background: var(--bgTheme);
}

.p-inputtext:enabled:focus {
    outline: none;
    box-shadow: none;
}

.p-calendar-w-btn-right .p-datepicker-trigger {
    margin: 0 10px;

    border: none;
    
    background: var(--bgTheme) !important;
    color: #D2D2D2;
}
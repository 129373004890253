@import '../../../../globalStyles/global.module.scss';

.modalContainer {
    width: 40%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 20px;
    padding: 18px 24px;
    border-radius: $standard-border-radius;
    border: 1px solid #858585;

    font-size: 20px;

    background-color: $background-items;
    color: #ccc;
}

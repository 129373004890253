@import '../../../../../globalStyles/global.module.scss';

.typePicker {
    display: flex;

    gap: 15px;
    accent-color: #00c2fd;

    font-size: 18px;

    div {
        gap: 15px;

        span {
            width: max-content;
            max-height: 125px;

            display: flex;
            flex-direction: column;

            overflow: auto;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #D2D2D2;
                border-radius: 3px;
            }
        }

        .timePickers {
            display: flex;
            flex-direction: column;
        }

        .secondTimePicker {
            display: flex;
            align-items: center;

            margin-top: 10px;
        }

        p {
            font-size: 12px;

            margin: 0 60px 10px 0;
            color: #00C2FD;

            cursor: pointer;
        }
    }

    .pickerTitles {
        display: flex;
        flex-direction: column;

        margin-top: 5px;
        gap: 35px;

        .pickerTitleBottom {
            max-width: 189px;
            position: absolute;
            bottom: 78px;
        }
    }
}

.timePickerTop {
    margin-bottom: 10px;

    display: flex;
    gap: 20px;
    align-items: center;

}

.closeIcon {
    cursor: pointer;

    &:hover {
        svg path {
            fill: #00C2FD;
        }

        svg circle {
            stroke: #00C2FD;
        }
    }
}

.empty {
    width: 45px;
}
@import '../../../../../globalStyles/global.module.scss';

.modalContainer {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 20px;
  padding: 18px 24px;
  border: 1px solid #858585;
  border-radius: $standard-border-radius;

  font-size: 20px;

  background-color: $background-items;
  color: #ccc;
}

.modalContent {
  font-size: 22px;
  margin: 4px 0;
  text-align: center;

  color: white;
}

.checkInput {
  width: calc(100% - 22px);

  padding: 10px;

  border: 1px solid #858585;
  border-radius: $standard-border-radius;

  font-size: 18px;

  background: inherit;
  color: white;

  &:focus {
    outline: none;
  }
}

.styleInput{
  width: 100%;

  margin-left: -5px;

  border: none;

  background: inherit;
  color: #cfcfcf;

  font-size: 20px;

  &:focus {
    outline: none;
  }
}

.inputBox{
  width: calc(100% - 20px);
  height: 40px;

  padding: 0 10px;

  display: flex;
  align-items: center;

  border: 1px solid #858585;
  border-radius: $standard-border-radius;

  color: #cfcfcf;

  font-size: 20px;

  &:hover {
    border: 1px solid #00C2FD;
  }

  &:focus-within {
    border: 1px solid #00C2FD;
  }
}

.passwordToggle{
  width: 30px;
  height: 20px;

}
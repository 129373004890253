@import '../../../globalStyles/global.module';

.searchField {
	width: 49%;
	height: 50px;

	z-index: 20;

	border-radius: $standard-border-radius;
	border: 1px solid #858585;

	background-color: $background-items;

	transition: height 0.3s ease-in-out;

	&.open {
		height: 40vh;
	}
}

.selectName {
	width: 100%;
	margin: 0 10px;
}

.title {
	width: 100%;
	
	display: flex;
	align-items: center;
	
	cursor: pointer;
}

.titleSearch {
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: space-between;
}

.menuSearch {
	width: calc(49% - 20px);
	height: 52px;

	display: flex;
	justify-content: space-between;
	align-items: center;

	position: absolute;
	top: 0;

	padding: 0 10px;
}

.search {
	width: 100%;

	padding: 5px;
	margin-left: 10px;

	display: flex;
	align-items: center;

	border: 1px solid #858585;
	border-radius: $standard-border-radius;

	&:hover {
        border: 1px solid #00C2FD;
    }

	&:focus-within {
		border: 1px solid #00C2FD;
	}
}

.searchInput {
	width: 100%;

	padding: 0 5px;
	margin-left: 10px;
	
	border: none;

	font-size: 20px;

	background: inherit;
	color: white;

    &:focus {
        outline: none;
    }
}

.closeIcon {
	display: flex;

	cursor: pointer;

	&:hover {
		svg path {
			fill: #00C2FD;
		}
	}
}

.icon {
	display: flex;

	cursor: pointer;

	&:hover {
		svg path {
			fill: #00C2FD;
		}
	}
}
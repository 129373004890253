@import '../../../globalStyles/global.module.scss';

.box{
  width: calc(33% - 5px);
  height: calc(100% - 15px);

  display: flex;
  flex-direction: column;

  border: 1px solid #858585;
  border-radius: $standard-border-radius;

  background-color: $background-items;
}

.content{
  width: 100%;
  height: calc(100% - 20px);


  display: flex;
  flex-direction: column;

  overflow-y: auto;
  overflow-x: auto;
}

.content::-webkit-scrollbar{
  width: 6px
}

.content::-webkit-scrollbar-thumb {
  background-color:  #ccc;
  border-radius: 3px
}

.container {
	font-size: 18px;
	color: #d2d2d2;
}

.titleInfo {
	font-size: 20px;
	padding: 10px 16px;
}

audio {
	width: 100%;
	height: 40px;
	background-color: #f1f1f1;
	border-radius: 5px;
	padding: 2px;
}

audio::-webkit-media-controls-play-button {
	background-color: transparent;
	color: #333;
	font-size: 20px;
}

audio::-webkit-media-controls-timeline {
	background-color: #f1f1f1;
}

audio::-webkit-media-controls-timeline-progress-bar {
	background-color: #333;
}

audio::-webkit-media-controls-volume-slider {
	background-color: #f1f1f1;
}

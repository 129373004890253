.titleBox {
    padding: 12px 21px 6px 10px;
}

.title {
	font-family: Inter, sans-serif;
	font-weight: 500;
	font-size: 20px;
	color: var(--colorText);

	user-select: none;
}

.list {
	max-height: calc(100% - 45px);
	overflow: auto;

	display: flex;
	flex-direction: column;

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #D2D2D2;
		border-radius: 3px;
	}
	
	.settingsItem {
		&:hover {
			.listItemIcon.settings {
				opacity: 1;
			}

			.iconSet {
				display: block;
			}
		}
	}

	.recName {
		font-family: Inter, sans-serif;
		font-weight: 400;
		font-size: 16px;
		color: var(--colorText);
		color: hsl(0, 0%, 75%)
	}
}

.iconSet {
	display: none;

	&:hover {
		svg path {
			fill: #00C2FD;
		}
	}
}

@import '../../../globalStyles/global.module.scss';

.imgContainer {
	width: calc(100% - $ml - $mr); // Ширина дочернего элемента с учетом отступов
	height: calc(100% - $mt - $mb); // Высота дочернего элемента с учетом отступов

	margin: $mt $mr $mb $ml; // Отступы от родительского элемента

	position: absolute;
	z-index: 2;

	display: flex;
	justify-content: center;

	border: 1px solid #858585;
	border-radius: $standard-border-radius;
	background-color: #272727;
}

.icons {
	position: absolute;
	top: 10px;
	right: 10px;
}

.img {
	max-width: 100%;
	object-fit: contain;
}
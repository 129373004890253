$backgroundUrl: '../../../assets/Icons/ControlPage/tempBgc.jpg';

.container {
	position: absolute;
	
    border-radius: 10px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

	.tempZone {
		width: 55%;
		height: 55%;

		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 10;

		transform: translate(-44%, -56%);

		.areaCanvas {
			position: absolute;
			border: 1px solid black;
		}
	}

	.currentTemp {
		position: absolute;

		top: 0;
		left: 0;
		z-index: 1;

		border-radius: 10px;
		padding: 5px 10px;

		margin: 10px;

		user-select: none;

		background: #272727;

		.currentTitle {
			font-size: 18px;
			color: #D2D2D2;
		}
	}

	.image {
		position: absolute;
		border-radius: 10px;
	}

	.fireIcon {
		width: 110px;
		
		display: flex;
		justify-content: space-between;

		position: absolute;
		right: 0;

		margin: 10px;
	}

	.colorLine {
		height: 30px; 

		display: flex;
		justify-content: space-between;
		align-items: center;
		
		position: absolute;
		bottom: 19%;
		right: 50%;
		transform: translateX(56%);

		border-radius: 10px;
		border: 1px solid #858585;

		color: #D2D2D2;
		background: #272727; 
	}

	.colorBlock {
		width: 70%;
		height: 20px;
		
		position: absolute;
		right: 50%;
		transform: translate(50%);

		opacity: 0.7;
		background: linear-gradient(to right, #0000FF, #0088FF, #00FFFF, #00FF88, #00FF00, #88FF00, #FFFF00, #FF8800, #FF0000);
	}
}

@import "../../globalStyles/global.module.scss";

.parent {
    width: 100vw;
	height: 100vh;
}

.container {
	width: 100%;
	height: 100%;
}

.buttonPos {
	position: fixed;
	margin: $margin-menu;
	z-index: 1000;
}
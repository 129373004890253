@import '../../../globalStyles/global.module.scss';

.polygonMenu {
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.confirmButtonsPos {
		margin: 10px;
	}

	.save {
		width: 100%;
		height: 40px;
		
		border-radius: $standard-border-radius;
		border: 2px solid #00c2fd;
	
		color: #00c2fd;
		font-size: 18px;
		font-family: 'Inter';
	
		background-color: inherit;
	
		&:hover {
			color: $background-items;
			background-color: #00c2fd;
		}
	}
}


.information {
	font-weight: 400;
	font-size: 12px;
	color: var(--colorText);
}

.emptyMessage {
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;

	text-align: center;

	font-weight: 400;
	font-family: 'Inter';
	text-transform: uppercase;

	.erTitle {
		font-size: 22px;
		color: var(--colorText);
	}

	.erMessage {
		font-size: 17px;
		color: hsl(31, 100%, 60%);
		cursor: pointer;
	};
}
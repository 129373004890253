.popupContainer {
    display: flex;
    flex-direction: column;
}

.links {
    position: absolute;
    bottom: 0;

    display: flex;
    flex-direction: column;

    padding: 10px 0;
}

.link {
    text-decoration: none;
    color: white !important;

    &:hover {
        color: #00C2FD !important;
    }
}

.listItemIcon{
    width: 14px;
    height: 14px;
  
    border-radius: 50%;
  
    background: #F6BA1B;
  }
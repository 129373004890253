.headerCard{
  width: calc(100% - 40px);
  height: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px;

  border-bottom: 1px solid #858585;
}

.title {
  width: calc(100% - 70px);

  font-family: 'Inter';
  font-size: 20px;
  line-height: 22px;

  color: #CDCDCD;
  user-select: none;
}

.iconsContainer{
  width: 60px;

  display: flex;
  justify-content: flex-end;
}

.settingItems{
  display: flex;

  cursor: pointer;

  &:hover {
    svg path {
      fill: #00C2FD;
    }

    svg circle {
      stroke: #00C2FD;
    }
  }
}

.icon{
  color:  #CDCDCD;
  cursor: pointer;

  &:hover {
    svg path {
      fill: #00C2FD;
    }

  }
}
.container {
  width: 100%;
  min-height: 70px;
  height: max-content;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.row {
  width: calc(50% - 10px);
  height: 50px;

  display: flex;

  color: #cdcdcd;
}

.modal{
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btnContainer{
  height: 50px;

  margin-top: 10px;
  margin-bottom: 10px;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.box{
  height: max-content;
  width: 100%;

  display: flex;
  flex-direction: column;
}

.agreeIcon{
  color:  #CDCDCD;
  cursor: pointer;

  &:hover {
    svg path {
      stroke: #00C2FD;
    }
  }
}

.closeIcon{
  width: 25px;

  cursor: pointer;

  &:hover {
    svg path {
      fill: #00C2FD;
    }

    svg circle {
      stroke: #00C2FD;
    }
  }
}


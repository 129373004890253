@import '../../../../../globalStyles/global.module.scss';

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 20px;
  padding: 18px 24px;
  border-radius: $standard-border-radius;

  font-size: 20px;

  background-color: $background-items;
  color: #ccc;
  border: 1px solid #858585;
}

.modalContent{
  user-select: none;
}


.checkInput {
  width: calc(100% - 22px);

  padding: 10px;

  border: 1px solid #858585;
  border-radius: $standard-border-radius;

  font-size: 18px;

  background: inherit;
  color: white;

  &:focus {
    outline: none;
  }
}

.modalButtons {
  width: 100%;

  display: flex;
  justify-content: space-between;
}

.modalDeleteButton {
  width: 40%;
  height: 40px;

  font-size: 20px;

  border: 2px solid #00c2fd;
  border-radius: $standard-border-radius;

  color: #00c2fd;
  background: transparent;

  &:disabled, &:disabled:hover {
    border: none;

    color: rgba(108, 108, 108, 0.8);
    background: transparent;
  }

  &:hover:not(:disabled) {
    cursor: pointer;

    color: $background-items;
    background: #00C2FD;
  }
}

.modalButton {
  width: 40%;
  height: 40px;

  font-size: 20px;
  border: none;

  border-radius: $standard-border-radius;
  cursor: pointer;

  color: #ccc;
  background: transparent;

  &:hover {
    border: 1px solid #858585;
  }
}
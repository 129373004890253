@import '@globalStyles/global.module.scss';

.main {
  width: 100%;
  height: 100%;

  position: absolute;
  z-index: 1;

  background: #272727;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.buttonPos {
  position: fixed;
  margin: $margin-menu;
  z-index: 2;
}

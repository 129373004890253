.container {
    user-select: none;
    
    font-size: 18px;
    color: #D2D2D2;
}

.titleInfo {
    font-size: 20px; 
    padding: 10px;
}

// margin: 8.57% 6.44% 2.49% 5.71%;
@import '../../globalStyles/global.module.scss';
$backgroundUrl: '../../assets/Icons/ControlPage/tempBgc.jpg';

.container {
	width: 100vw;
	height: 100vh;

	display: flex;
	align-items: center;

	background: #272727;

	.content {
		$width: 62%;
		$gapContent: 10px;

		$rightWidth: calc(100% - $width - $gapContent);

		width: calc(100% - $ml - $mr); // Ширина дочернего элемента с учетом отступов
		height: calc(100% - $mt - $mb); // Высота дочернего элемента с учетом отступов

		position: relative;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		gap: $gapContent;
		border-radius: 10px;

		margin: $mt $mr $mb $ml; // Отступы от родительского элемента

		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;

		.left {
			width: $width;

			display: flex;
			flex-direction: column;
			flex-wrap: wrap;

			gap: $gapContent;
		}

		.right {
			width: $rightWidth;

			display: flex;
			flex-direction: column;
			flex-wrap: wrap;

			gap: $gapContent;
		}

		.topLeft {
			width: 100%;
		    flex: 70 0 0;

			border-radius: 10px;
			border: 1px solid #858585;

			background-color: $background-items;
		}

		.bottomLeft {
			width: 100%;
		    flex: 30 0 0;

			border-radius: 10px;
			border: 1px solid #858585;

			background-color: $background-items;
		}

		.topRight {
			width: 100%;
		    flex: 55 0 0;

			border-radius: 10px;
			border: 1px solid #858585;

			background-color: $background-items;
		}

		.bottomRight {
			width: 100%;
		    flex: 45 0 0;

			background-color: $background-items;
		}
	}

	.buttonPos {
		position: fixed;
		z-index: 2;

		&.topLeft {
			top: $margin-menu;
			left: $margin-menu;
		}
	}
}

.modalSensors{
	width: calc(100% - $ml - $mr + 2px); // Ширина дочернего элемента с учетом отступов
	height: calc(100% - $mt - $mb + 6px); // Высота дочернего элемента с учетом отступов

	position: absolute;

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	gap: 10px;

	margin: $mt $mr $mb $ml; // Отступы от родительского элемента

	background: #272727;
	overflow-y: hidden;

	z-index: 500;
}

.toastMessage {
	border: 1px solid #858585;
	border-radius: $standard-border-radius !important;

	color: #D2D2D2 !important;
    background: $background-items !important;

	--toastify-icon-color-success: #00C2FD;
	--toastify-color-progress-success: #00C2FD;
}

.toastError {
	border: 1px solid #858585;
	border-radius: $standard-border-radius !important;

	color: #D2D2D2 !important;
    background: $background-items !important;
}

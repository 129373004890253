@import '../../../../../globalStyles/global.module.scss';

.modal{
  width: 80%;
  height: 60%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  background: #272727;
  border: 1px solid #858585;
  border-radius: $standard-border-radius;
  padding: 20px;
}

.titleCard{
  width: 100%;
  height: max-content;

  display: flex;
  justify-content: center;

  color: #CDCDCD;

  font-size: 20px;

  margin-bottom: 10px;
}

.content{
  height: calc(100% - 50px);

  border-radius: $standard-border-radius;
  border: 1px solid #858585;
}
@font-face{
    font-family: "HalvarBreit";
    src: local("HalvarBreit"),
    url("./fonts/HalvarBreit/HalvarBreit-Lt.ttf") format("truetype");
}
@font-face{
    font-family: "Inter";
    src: local("Inter"),
    url("./fonts/Inter/Inter-Regular.otf") format("truetype");
}

html {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: 'Inter', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
    width: 100%;
    height: 100%;
    
	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 10px;
	border: 1px solid #858585;

	user-select: none;
}

$backgroundUrl: '../../assets/backgroundMSDSVG.svg';
.main {
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	position: absolute;
	z-index: 1001;

	background: url($backgroundUrl);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.loadingTitleCard {
	width: 210px;

	display: flex;
	justify-content: space-between;
	align-items: center;
	align-content: center;
}

.loadingIcon {
	width: 15px;
	height: 15px;
}

.loadingTitle {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;

	color: #b1b1b1;
}

.downInf {
	// width: 200px;
	height: 34px;

	display: flex;
	justify-content: space-between;
	align-items: center;

	position: fixed;
	z-index: 1;

	bottom: 20px;
}

.devCard {
	margin: 0 10px;
}

.devTitle {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 19px;

	color: #b1b1b1;
}

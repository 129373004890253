@import '../../../../globalStyles/global.module.scss';


.card {
  min-width: 450px;

  width: 450px;
  height: calc(100% - 24px);


  position: relative;

  padding: 10px;

  display: flex;
  align-items: center;
  gap: 10px;

  border: 1px solid #858585;
  border-radius: $standard-border-radius;

  background-color: rgb(31, 31, 31);
  color: #ccc;
}

.chart {
  min-width: 98%;
  width: 98%;
}

.form{
  height: max-content;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.styleInput{
  width: 100%;

  padding: 10px;

  border: none;

  background: inherit;
  color: #cfcfcf;

  font-size: 1rem;

  &:focus {
    outline: none;
  }
}

.inputBox{
  width: calc(100% - 20px);
  height: 35px;

  padding: 0 10px;

  display: flex;
  align-items: center;

  border: 1px solid #858585;
  border-radius: $standard-border-radius;

  color: #cfcfcf;

  font-size: 20px;

  &:hover {
    border: 1px solid #00C2FD;
  }

  &:focus-within {
    border: 1px solid #00C2FD;
  }
}

.header{
  width: calc(100% - 20px);

  position: absolute;

  top: 10px;
  right: 10px;

  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.iconsDef{
  width: max-content;

  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.closeIcon{
  cursor: pointer;

}

.closedIcons{
  opacity: 0;
}

.chartsHeader{
  top: 10px;
  left: 10px;

  justify-content: flex-start;
}

.icon{
  width: 100%;
  height: 100%;

  cursor: pointer;

  &:hover {
    svg path {
      fill: #00C2FD;
    }
  }
}

.agreeIcon{
  color:  #CDCDCD;
  cursor: pointer;

  &:hover {
    svg path {
      stroke: #00C2FD;
    }
  }
}

.iconContainer{
  width: 100px;

  display: flex;
  align-items: center;
}

.right{
  height: 100%;
  width: 100%;

  display: flex;
  margin: 5px 0;

  font-size: 16px;
  font-family: 'Inter';
  letter-spacing: 0.05em;
}

.rightTitle{
  width: 90%;

  display: flex;
  justify-content: flex-start;
}

.rightValue{
  width: 10%;

  display: flex;
  justify-content: flex-end;
  gap: 0;
  white-space: nowrap;
}

.content{
  width: 100%;
  height: calc(100% - 40px);

  padding: 10px;

  margin-top: 20px;

  display: flex;
  justify-content: center;
  align-content: center;
  gap: 10px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #D2D2D2;
    border-radius: 3px;
  }
}
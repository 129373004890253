@import '../../../globalStyles/global.module.scss';

.container {
    position: absolute;
    bottom: $margin-menu;
    left: $ml;

    display: flex;
}

.bottomIcons {
    margin-right: 10px;
}
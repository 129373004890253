@import '../../../../../globalStyles/global.module.scss';

.modal{
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.modalDelete{
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  gap: 10px;
}

.header{
  width: 100%;

  display: flex;
  justify-content: flex-end;
  align-content: center;

  font-size: 20px;
  color: #CDCDCD;
}

.headerContent{
  width: calc(50% + 60px);

  display: flex;
  justify-content: space-between;
}

.box{
  height: max-content;
  width: 100%;

  display: flex;
  flex-direction: column;
}

.iconContainer{
  width: 60px;

  position: absolute;
  top: 30px;
  right: 37px;
  z-index: 1;

  display: flex;
  gap: 10px;
  justify-content: flex-end;

  cursor: pointer;
}

.closeIcon{
  width: 60px;

  display: flex;
  gap: 10px;

  position: absolute;
  top: 30px;
  right: 50%;
  z-index: 1;

}

.modalResetPassword{
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon{
  cursor: pointer;
  &:hover {
    svg path {
      fill: #00C2FD;
    }

    svg circle {
      stroke: #00C2FD;
    }
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.container {
  width: 100%;
  height: calc(100% - 100px);
}

.title{
  padding: 10px 0;

  display: flex;
  justify-content: left;

  font-family: 'Inter';
  font-size: 18px;
  color: #CDCDCD;
}

.btnContainer{
  height: 80px;

  margin-top: 10px;
  margin-bottom: 10px;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.styleInput{
  width: 100%;

  padding: 10px;

  margin-left: 10px;

  border: none;

  font-size: 18px;

  background: inherit;
  color: #cfcfcf;
  &:focus {
    outline: none;
  }
}

.inputBox{
  width: calc(100% - 20px);

  padding: 0 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  border: 1px solid #858585;
  border-radius: $standard-border-radius;

  color: #cfcfcf;

  font-size: 20px;

  &:hover {
    border: 1px solid #00C2FD;
  }

  &:focus-within {
    border: 1px solid #00C2FD;
  }
}
.container{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #272727;
}

.text{ 
    font-size: 25px;
    color: #00C2FD;
}
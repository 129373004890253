@import '../../../globalStyles/global.module.scss';

.map {
 padding: 20px;
}

.card {
  border: 1px solid #858585;
  border-radius: $standard-border-radius;

  background-color: $background-items;
}

.headerCard {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 10px 3px 0 21px;

  font-family: 'Inter', sans-serif;
  font-size: 20px;

  text-transform: uppercase;

  color: #CDCDCD;
}

.table {
  height: calc(100% - 66.4px);

  &.fullWin {
    height: calc(100% - 86.4px);
    padding: 0 20px 20px 20px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #D2D2D2;
    border-radius: 3px;
  }
}
.container {
	width: 100%;
	height: 100%;

	border-radius: 10px;
}

.leaflet-container a.leaflet-popup-close-button {
	display: none;
}

.leaflet-popup-tip-container {
	display: none;
}

.leaflet-popup-content-wrapper {
	width: 180px;
	height: 50px;

	padding: 10px !important;

	font-size: 14px !important;

	border-bottom-right-radius: 10px !important;
	border-top-left-radius: 10px !important;

	color: white !important;
	background: #545454 !important;
}

.leaflet-popup {
	left: 0 !important;
}

.leaflet-popup-content {
	margin: 0;
	line-height: 1.1 !important;
}

.leaflet-bottom .leaflet-control-scale {
	margin: 0 !important;
}

.leaflet-control-scale-line {
	height: 50px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin: 20px 0 20px 280px;

	font-size: 18px;
	font-family: 'Inter';

	border: none !important;
	border-radius: 10px;
	text-shadow: none !important;

	color: white !important;
	background-color: rgba(78, 78, 78, 0.8) !important;
}

.leaflet-control-attribution a {
	display: none;
}

.leaflet-grab {
	cursor: pointer;
}

.custom-marker-cluster {
	text-align: center;
	line-height: 34px;

	border-radius: 50%;
	border: 2px solid #00c2fd;

	box-shadow: 0px 0px 44px 4px #00c2fd;

	color: white;
	background: #373737;
}

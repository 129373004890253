@import '@globalStyles/global.module.scss';

.container{
	margin: $mt $mr $mb $ml; // Отступы от родительского элемента
	width: calc(100% - $ml - $mr); // Ширина дочернего элемента с учетом отступов
	height: calc(100% - $mt - $mb); // Высота дочернего элемента с учетом отступов

	display: flex;

	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 2;

	> div {
		width: 100%;
	}
}

.box{
	width: calc(33% - 2px);
	height: 100%;

	display: flex;

	border-radius: $standard-border-radius;

	background-color: $background-items;
}

.header{
	width: 100%;
	height: 60px;

	display: flex;
	justify-content: space-between;
	align-content: center;

	color: #CDCDCD;
	font-size: 20px;
}

.card{
	width: 100%;

	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;

	padding: 20px;

	border: 1px solid #858585;
	border-radius: $standard-border-radius;

	color: #cfcfcf;
	font-size: 18px;

}

.boxCards{
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: space-between;
}

.title {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;

	color: #CDCDCD;
}

.btnTitle {
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;

	margin-right: 15px;
	color: #00C2FD;

	cursor: pointer;
}

.headerCard{
	width: calc(100% - 40px);
	height: 20px;

	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 20px;
}

.footerResetFilter {
  height: 52px;
  width: 100%;

  border-top: 1px solid #515151;
}

.resetFilterButton {
  height: 32px;
  width: calc(100% - 20px);

  margin: 10px;

  position: absolute;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  border: none;

  font-size: 16px;

  background: none;
  color: white;

  cursor: pointer;

  &:hover {
    color: #00C2FD;
  }
}
@import '../../../../../globalStyles/global.module.scss';


.card {
  max-width: 300px;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.header{
  width: 100%;

  position: absolute;

  top: 10px;
  right: 10px;
  z-index: 10;

  display: flex;
  justify-content: flex-end;

  gap: 10px;
}

.iconContainer{
  width: 40%;
}

.right{
  height: 30px;
  width: 100%;

  display: flex;
  justify-content: space-between;

  gap: 10px;

  font-size: 1rem;
  font-family: 'Inter';
  letter-spacing: 0.05em;
}

.rightTitle{
  width: 100% ;

  display: flex;
  justify-content: flex-start;
}

.rightValue{
  width: 84px;

  display: flex;
  justify-content: flex-end;

  gap: 0;
  white-space: nowrap;
}
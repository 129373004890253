@import '../../../globalStyles/global.module.scss';

.container {
	max-height: 80%;

	position: absolute;
	top: 50%;
	left: 50%;

	padding: 32px;
	border: 1px solid #858585;
	border-radius: $standard-border-radius;

	transform: translate(-50%, -50%);

	overflow: auto;

	background-color: #272727;
	color: #d2d2d2;

	font-family: Inter, sans-serif;

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #ccc;
		border-radius: 3px;
	}
}

.heads {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	font-size: 18px;
	margin: 10px;
}

.headBas {
	display: flex;
	flex-direction: column;
	text-align: center;
	font-size: 20px;
}

.headProt {
	display: flex;
	flex-direction: column;
	text-align: center;
	font-size: 20px;
	margin: 20px;
}

.download {
	margin-top: 20px;
	text-align: center;
	font-size: 18px;
}

.title {
	position: relative;

	display: flex;
	justify-content: center;

	margin: 10px;
	text-align: center;
	font-size: 18px;
}

table {
	width: 100%;
	margin-bottom: 20px;
	border: 1px solid #dddddd;
	border-collapse: collapse;
}
table th {
	padding: 5px;
	background: #efefef;
	border: 1px solid #dddddd;
}
table td {
	text-align: center;
	justify-content: center;
	border: 1px solid #dddddd;
}

form {
	display: flex;
	justify-content: flex-start;
	margin: 10px 0;
}

.item {
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	font-size: 18px;
}

.iconDeleteItem{
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: flex-end;
	font-size: 24px;

	padding: 11px 10px;

	cursor: pointer;

	&:hover {
		svg path {
			fill: #00C2FD;
		}

	}
}

.inputBox {  
	margin: 5px;
  
	display: flex;
	flex-direction: column;
	align-items: center;
  
	border: 1px solid #858585;
	border-radius: $standard-border-radius;  
  
	&:hover {
	  border: 1px solid #00C2FD;
	}
  
	&:focus-within {
	  border: 1px solid #00C2FD;
	}
  }

.styleInput {
	width: calc(100% - 10px);

	padding: 5px;
	// margin: 5px;
	font-size: 18px;
	
	border: none;
	border-radius: $standard-border-radius; 
	
	background-color: #272727;
	color: #d2d2d2;

	&:focus {
		outline: none;
	}
}

.obj {
	padding: 10px;
	margin: 20px 0;
	border-radius: 5px;
	background: rgba(200, 200, 200, 0.12);
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.objTwo {
	padding: 10px 40px;
	margin-top: 20px;
	border-radius: 5px;
	background: rgba(200, 200, 200, 0.12);
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.els {
	display: flex;
	justify-content: space-between;
}

p {
	display: flex;
	text-align: center;
	justify-content: center;
	font-size: 18px;
	padding-top: 5px;
	margin: 0 5px;
}

.modalButtons {
	width: 100%;

	display: flex;
	justify-content: space-between;

	margin-top: 20px;
}

.modalDeleteButton {
	width: 40%;
	height: 40px;

	font-size: 20px;

	border: 2px solid #00c2fd;
	border-radius: $standard-border-radius;

	color: #00c2fd;
	background: transparent;

	&:disabled,
	&:disabled:hover {
		border: none;

		color: rgba(108, 108, 108, 0.8);
		background: transparent;
	}

	&:hover:not(:disabled) {
		cursor: pointer;

		color: rgba(31, 31, 31, 1);
		background: #00c2fd;
	}
}
.modalButton {
	width: 40%;
	height: 40px;

	font-size: 20px;
	border: none;

	border-radius: $standard-border-radius;
	cursor: pointer;

	color: #ccc;
	background: transparent;

	&:hover {
		border: 2px solid #ccc;
	}
}

.icon{
	position: absolute;
	right: 0;
	
	color:  #CDCDCD;
	cursor: pointer;
  
	&:hover {
	  svg path {
		fill: #00C2FD;
	  }
  
	}
  }

@import '@globalStyles/global.module.scss';

.fullScreen {
  width: 100%;
  height: 100%;

  position: absolute;
  z-index: 1;

  background: #272727;
}

.buttonPos {
  position: fixed;
  margin: $margin-menu;
  z-index: 2;
}

.toastMessage {
  border: 1px solid #858585 !important;
  border-radius: $standard-border-radius !important;

  color: #D2D2D2 !important;
  background: $background-items !important;

  --toastify-icon-color-success: #00C2FD;
  --toastify-color-progress-success: #00C2FD;
}

.toastError {
  border: 1px solid #858585 !important;
  border-radius: $standard-border-radius !important;

  color: #D2D2D2 !important;
  background: $background-items !important;
}

@import '@globalStyles/global.module.scss';

.container {
  width: calc(100% - $ml - $mr);
  height: calc(100% - $mt - $mb);

  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: column;

  margin: $mt $mr $mb $ml;

  overflow-y: hidden;
}

.box {
  width: calc(33% - 5px);
  height: calc(100% - 15px);

  display: flex;
  flex-direction: column;

  border: 1px solid #858585;
  border-radius: $standard-border-radius;

  background-color: $background-items;
}

.boxCards {
  width: 100%;
  height: 50%;

  display: flex;
  justify-content: space-between;
}

.boxCards::-webkit-scrollbar{
  width: 6px
}

.boxCards::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 3px
}

.boxCardsTop {
  width: 100%;
  height: 50%;

  display: flex;
  justify-content: space-between;
}

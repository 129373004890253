$controlPanelWidth: 130px;
$controlPanelHeight: 130px;

.parent {
	width: $controlPanelWidth;
	height: $controlPanelHeight;

	border: 2px solid #545454;
	border-radius: 50%;

	background: #1f1f1f;

	position: relative;
}

.child {
	position: absolute;

	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.customButton {
	width: 50px;
	height: 50px;

	border-radius: 50%;

	display: flex;
	align-items: center;
	justify-content: center;

	position: absolute;
	cursor: pointer;

	color: transparent;
	transition: color 0.3s, border-color 0.2s, background-color 0.2s;

	font-family: Inter;
	font-size: 18px;
	font-weight: 600;

	user-select: none;

	box-sizing: border-box;

	span {
		padding-top: 2px;
	}

	&.top {
		border-top: 2px solid #545454;
		border-bottom: 2px solid transparent;
	}

	&.left {
		border-left: 2px solid #545454;
		border-right: 2px solid transparent;
	}

	&.right {
		border-right: 2px solid #545454;
		border-left: 2px solid transparent;
	}

	&.bottom {
		border-bottom: 2px solid #545454;
		border-top: 2px solid transparent;
	}

	&:hover {
		border: 2px solid #00c2fd;
		color: #00c2fd;

		&:active {
			border: 2px solid hsl(194, 100%, 40%);
			color: hsl(194, 100%, 40%);
			background-color: hsl(0, 0%, 14%);
		}
	}	
}


.customButtonKeyboard {
	width: 50px;
	height: 50px;

	border-radius: 50%;

	display: flex;
	align-items: center;
	justify-content: center;

	position: absolute;
	cursor: pointer;

	color: transparent;
	transition: color 0.3s, border-color 0.2s, background-color 0.2s;

	font-family: Inter;
	font-size: 18px;
	font-weight: 600;

	user-select: none;

	box-sizing: border-box;

	span {
		padding-top: 2px;
	}

	&.top {
		border: 2px solid #00c2fd;
		color: #00c2fd;
		// background-color: hsl(0, 0%, 14%);
	}

	&.left {
		border: 2px solid #00c2fd;
		color: #00c2fd;
	}

	&.right {
		border: 2px solid #00c2fd;
		color: #00c2fd;
	}

	&.bottom {
		border: 2px solid #00c2fd;
		color: #00c2fd;
	}
}
@import '../../../globalStyles/global.module.scss';


.main{
  width: 100%;
  height: 100%;

  position: absolute;
  z-index: 1;
}

.letter{
  padding-left: 5px;

  text-align: center;

  font-family: 'Nachlieli CLM';

  font-weight: 600;
  font-size: 100px;

  color: #ccc;
}

.tempIcon{
  margin-left: -50px;
}

.tempTitle{
  margin-left: -50px;
}

.wrapper{
  width: 100%;
  height: calc(100% / 4);
}

.content{
  $rowWidth: calc(25% - 10px);
  width: calc(100% - $mr - $ml);
  height: calc(100% - $mt - $mb); // Высота дочернего элемента с учетом отступов

  display: grid;
  grid-template-rows: $rowWidth $rowWidth $rowWidth $rowWidth;
  grid-row-gap: 10px;

  border: none;



  margin: $mt $mr $mb $ml; // Отступы от родительского элемента
}
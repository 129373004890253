@import '../../../../globalStyles/global.module.scss';

.brick {
  height: 50px;
  width: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  border: 1px solid #858585;

  background-color: $background-items;

  cursor: pointer;
}


.markIsNew{
  width: 8px;
  height: 8px;

  border-radius: 8px;

  position: absolute;
  right: 2px;
  top: 2px;

  background:  #00C2FD;
}

.item {
  height: 75px;
  padding: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  border-top: 1px solid #858585;
  border-bottom: 1px solid #858585;
}

.listItemIcon{
  width: 21px;
  height: 21px;

  border-radius: 50%;

  background: #F6BA1B;
}

.navLink {
  width: calc(100% - 25px);

  display: flex;
  justify-content: center;
  align-content: center;
  gap: 5px;

  margin-left: 10px;

  text-decoration: none;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #cdcdcd;

  &:hover {
    color: #00C2FD !important;
  }

}

.descriptionText{
  width: 28%;
  height: 48px;

  display: flex;
  align-items: center;
}

.messageText{
  width: 38%;
  height: 48px;

  display: flex;
  align-items: center;
}

.dateText{
  width: 110px;
  height: 48px;

  display: flex;
  align-items: center;

  text-align: right;
}

.conditionalStyle{
  width: 21px;
  height: 21px;

  border-radius: 10px;
}

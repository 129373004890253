@import '../../../globalStyles/global.module.scss';

.modalButtons {
	width: 100%;

	display: flex;
	justify-content: space-between;

	.modalButton {
		width: 40%;
		height: 40px;

		border-radius: $standard-border-radius;
		border: 2px solid #00c2fd;

		color: #00c2fd;
		background: transparent;

		&:disabled,
		&:disabled:hover {
			border: none;

			color: rgba(108, 108, 108, 0.8);
			background: transparent;
		}

		&:hover:not(:disabled) {
			cursor: pointer;

			color: $background-items;
			background: #00c2fd;
		}
	}

	.modalCancelButton {
		width: 40%;
		height: 40px;

		border: none;

		border-radius: $standard-border-radius;
		cursor: pointer;

		color: #ccc;
		background: $background-items;

		&:hover {
			border: 2px solid #ccc;
		}
	}
}
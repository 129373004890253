@import '../../globalStyles/global.module.scss';

.container {
	width: calc(100% - $ml - 187px);

	position: absolute;
	top: $margin-menu;
	left: $ml;

	display: flex;
	justify-content: space-between;

	z-index: 1000;

	font-size: 18px;
	color: #D2D2D2;

	&.mapContainer {
		width: 450px !important;
	}
}

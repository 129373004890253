@import '@globalStyles/global.module.scss';

.page {
  width: calc((100vh - 95px) / 1.4);
  height: calc(100vh - 110px);
  overflow: hidden;

  background: $background-items;

  .closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;

    z-index: 100;
  }

  .buttonsContainer {
    width: 100%;

    display: flex;
    justify-content: center;

    position: absolute;
    bottom: 0;
    z-index: 100;
  }
}


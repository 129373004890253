.zeroData {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 18px;
    color: #D2D2D2;
}

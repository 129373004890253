@import '@globalStyles/global.module.scss';

.container {
	width: 100vw;
	height: 100vh;

	display: flex;
	justify-content: center;
	align-items: center;

	background: #272727;

    .buttonPos {
		position: fixed;
		z-index: 2;

		&.topLeft {
			top: $margin-menu;
			left: $margin-menu;
		}
	}

	.content {
		$width: 65%;
		$gapContent: 10px;

		$rightWidth: calc(100% - $width - $gapContent);

		width: calc(100% - $ml - $mr); // Ширина дочернего элемента с учетом отступов
		height: calc(100% - $mt - $mb); // Высота дочернего элемента с учетом отступов

		display: flex;
		flex-direction: column;
		flex-wrap: wrap;

		gap: $gapContent;
		border-radius: 10px;

		margin: $mt $mr $mb $ml; // Отступы от родительского элемента
		background-color: lightblue;

		background: black;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;

		position: relative;

		.topLeft {
			width: $width;
			flex: 65 0 0;
		}

		.bottomLeft {
			width: $width;
			flex: 35 0 0;
		}

		.right {
			width: $rightWidth;
			flex: 1 0 100%;
		}
	}

    .tempButton {
        position: absolute;
        top: 35px;
        left: 50%;
        z-index: 1;
    
        transform: translate(-50%, -50%);
    
        padding: 8px 16px;
    
        border-radius: $standard-border-radius;
        
        font-family: 'Inter', sans-serif;
        font-size: 20px;
    
        user-select: none;
    
        background-color: #00c2fd;
        color: #fff;
    }

    .currentTemp {
        position: absolute;
    
        top: 0;
        left: 0;
        z-index: 1;
    
        border-radius: 10px;
        padding: 5px 10px;
    
        margin: 20px;
    
        user-select: none;
    
        background: #272727;
    
        .currentTitle {
            font-size: 18px;
            color: #D2D2D2;
        }
    }

    .fireIcon {
        width: 110px;
        
        display: flex;
        justify-content: space-between;
    
        position: absolute;
        right: 70px;
    
        margin: 10px 0;
    
        z-index: 1;
    }

    .online {
        position: absolute;
        right: 0;
    
        margin: 10px;
    
        z-index: 1;
    }

	.btnOnline {
		width: 50px;
		height: 50px;

		display: flex;
		justify-content: center;
		align-items: center;

		border-radius: 10px;
		border: 1px solid #858585;

		user-select: none;

		background-color: $background-items;
	}

	.cameraControlView {
		position: absolute;
		right: 21px;
		bottom: 21px;
	}

	.additionalButtons {
		width: 176px;

		display: flex;
		justify-content: space-between;
		gap: 10px;

		position: absolute;
		right: 0px;
		top: calc(100% + 10px);
	}
}

.toastMessage {
	border: 1px solid #858585;
	border-radius: $standard-border-radius;

	color: #D2D2D2;
    background: $background-items;

	--toastify-icon-color-success: #00C2FD;
	--toastify-color-progress-success: #00C2FD;
}

.toastError {
	border: 1px solid #858585;
	border-radius: $standard-border-radius;

	color: #D2D2D2;
    background: $background-items;
}
@import '../../../../globalStyles/global.module.scss';

.titleBox {
    padding: 12px 21px 6px 10px;
}

.title {
	font-family: Inter, sans-serif;
	font-weight: 500;
	font-size: 20px;
	color: var(--colorText);

	user-select: none;
}

.content {
    height: 90%;

	padding: 0 10px;

	overflow: auto;
}

.content::-webkit-scrollbar {
	width: 6px;
};

.content::-webkit-scrollbar-thumb {
	background-color: #ccc;
	border-radius: 3px;
};

.subTitle {
	padding: 7px 0;
	font-size: 16px;
}

.search {
	padding: 5px;

	display: flex;
	align-items: center;

	border: 1px solid #858585;
	border-radius: $standard-border-radius;

	&:hover {
		border: 1px solid #00c2fd;
	}

	&:focus-within {
		border: 1px solid #00c2fd;
	}
}

.searchInput {
	width: 100%;

	padding: 5px;

	border: none;

	font-size: 16px;

	background: inherit;
	color: white;

	cursor: pointer;

	&:focus {
		outline: none;
	}
}

.closeIcon {
	display: flex;

	cursor: pointer;

	&:hover {
		svg path {
			fill: #00c2fd;
		}
	}
}

.confirm {
	width: calc(100% - 40px);

	position: absolute;
	bottom: 20px;
}
@import '../../../globalStyles/global.module.scss';

.box{
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.containerButtons{
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.containerButtonsEvent{
  width: calc(100% - 40px);

  position: absolute;
  bottom: 80px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.tempZone {
  width: 55%;
  height: 55%;

  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;

  transform: translate(-48%, -56%);


  .areaCanvas {
    position: absolute;
    border: 1px solid black;

    z-index: 100;
  }
}

.image {
  height: 361px;
  border-radius: 10px;
}

.buttonPeriod{
  width: calc(100% - 20px);
  height: 40px;

  padding: 0 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 1px solid #858585;
  border-radius: $standard-border-radius;

  color: #cfcfcf;

  font-size: 20px;

  &:hover {
    border: 1px solid #00C2FD;
    color: #00C2FD;

  }

  &:focus-within {
    border: 1px solid #00C2FD;
  }

  cursor: pointer;
  user-select: none;
}

.buttonEvent{
  width: calc(100% - 20px);
  height: 40px;

  padding: 0 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  border: 1px solid #858585;
  border-radius: $standard-border-radius;

  color: #cfcfcf;

  font-size: 14px;

  &:hover, &.active {
    border: 1px solid #00C2FD;
    color: #00C2FD;
  }


  &:focus-within {
    border: 1px solid #00C2FD;
  }

  cursor: pointer;
  user-select: none;
}

.imageBox{
  width: 100%;

  aspect-ratio: 16/9;

  position: relative;

  border-radius: 10px;

  background: rgba(0,0,0,0.5);
  object-fit: cover;
}

.header{
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-content: center;

  color: #CDCDCD;
  font-size: 20px;
}

.contentbox{
  width: 100%;
  height: 100%;
}

.parametersBox{
  width: 100%;
  height: calc(35vh - 120px);


  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-bottom: 120px;
  margin-top: 10px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #D2D2D2;
  }
}

.fullWindowIcon{
  cursor: pointer;
}

.title{
  width: 100%;

  display: flex;

  color: #CDCDCD;
  font-size: 20px;
}

.imageBox{
  width: 100%;
  height: max-content;

  color: #CDCDCD;
  font-size: 20px;
}

.center{
  width: calc(100% - 40px);

  position: absolute;
  bottom: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: #CDCDCD;
}

.dataPickerContainer{
  width: 100%;
  height: 80px;

  display: flex;
  flex-direction: column;
  align-content: center;
  gap: 10px;

  font-size: 20px;
  color: #CDCDCD;
}

.card{
  width: 100%;

  position: relative;

  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 20px;

  border: 1px solid #858585;
  border-radius: $standard-border-radius;
}


.buttonPeriod {
  width: calc(100% - 20px);
  height: 40px;

  padding: 0 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 1px solid #858585;
  border-radius: $standard-border-radius;

  color: #cfcfcf;

  font-size: 20px;

  &:hover, &.active {
    border: 1px solid #00C2FD;
    color: #00C2FD;
  }

  &:focus-within {
    border: 1px solid #00C2FD;
  }

  cursor: pointer;
  user-select: none;
}


.arrowsIconBtn{
  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  border: 1px solid #858585;

  user-select: none;

  cursor: pointer;

  background-color: $background-items;

  &:hover {
    svg path {
      fill: #00C2FD;
    }

    svg circle {
      stroke: #00C2FD;
    }
  }

  margin: 10px 0;
}

.arrowsIconBtnDisabled{
  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  border: 1px solid hsl(0, 0%, 40%);

  user-select: none;

  background-color: hsl(0, 0%, 10%);

  margin: 10px 0;

  svg path {
    fill:  hsl(0, 0%, 20%);
  }
}

.rightArrow{
  transform: rotate(180deg);
}
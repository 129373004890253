@import '../../../globalStyles/global.module.scss';
$primary-color: #00C2FD;

.box{
  height: max-content;
  width: 100%;

  display: flex;
  flex-direction: column;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.titleBox{
  height: max-content;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;

  position: relative;

  font-size: 20px;
  font-family: 'Inter';
  color: #cfcfcf;

}

.modalDelete{
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 100500;
}

.titleIcon{
  width: 20px;
  height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  cursor: pointer;

  &:hover {
    svg path {
      fill: #00C2FD;
    }

    svg circle {
      stroke: #00C2FD;
    }
  }
}

.container {
  width: 100%;
  height: calc(100% - 100px);
}

.btnContainer{
  height: 80px;

  margin-top: 10px;
  margin-bottom: 10px;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.textField{
  border-radius: 10px;
}

.inputRounded .MuiOutlinedInput-root{
  border-radius: 50px;
}

.inputDataBox{
  width: 100%;
  height: 20px;

  z-index: 20;

  border-radius: 10px;
  border: 1px solid #858585;
  color: #cfcfcf;

  background: $background-items;

  &.open {
    height: 20vh;
  }

}

.selectName {
  width: 100%;
  margin: 0 10px;

  cursor: pointer;
}

.placeItem {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px;
  margin: 2px 20px 2px 0;

  border-radius: 10px;
  border: 1px solid $background-items;

  cursor: pointer;
  color: #cfcfcf;

  &.show {
    border: 1px solid #00C2FD;

    color: #00C2FD;
  }

  &:hover {
    .navigateItem {
      display: block;
    }

    color: #00C2FD;

    border: 1px solid #00C2FD;
  }
}

.modal{
  width: 50%;
  height: max-content;
  max-height: 85%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: #272727;
  border: 1px solid #6C6C6C;
  border-radius: $standard-border-radius;

  padding:  20px;
  overflow-y: auto;
}

.modal::-webkit-scrollbar{
  width: 6px
}

.modal::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 3px
}

.content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inputBox{
  width: calc(100% - 20px);

  padding: 0 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  border: 1px solid #858585;
  border-radius: $standard-border-radius;

  color: #cfcfcf;

  font-size: 20px;

  &:hover {
    border: 1px solid #00C2FD;
  }

  &:focus-within {
    border: 1px solid #00C2FD;
  }
}

.styleInput{
  width: 100%;

  padding: 10px;

  margin-left: 10px;

  border: none;

  background: inherit;
  color: #cfcfcf;

  font-size: 18px;

  &:focus {
    outline: none;
  }
}

.lineStyle{
  width: 50%;

  display: flex;
  justify-content: space-between;
  align-content: center;
}

.titleModal{
  font-size: 20px;
  font-family: 'Inter';
  color: #CDCDCD;
}

.title{
  padding: 10px 0;


  display: flex;
  justify-content: left;

  font-family: 'Inter';
  font-size: 18px;
  color: #CDCDCD;
}

.chart {
  width: 100%;
  height: calc(100% - 30px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date {
  padding-left: 8px;

  color: #cdcdcd;
}
@import '@globalStyles/global.module.scss';

.container {
  width: 100%;

  display: flex;
  justify-content: space-between;

  background:  rgba(31, 31, 31, 0.5);
}

.center {
  width: calc(100% - 120px - 60px);

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 60px;

  .textPage {
    margin: 0 10px;

    font-family: 'Inter', sans-serif;
    font-size: 18px;
  }
}

.left {
  width: 120px;

  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  gap: 10px;
}

.right {
  width: 60px;

  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  gap: 10px;
}

.arrowsIconBtn {
  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 10px 0;

  border-radius: 10px;
  border: 1px solid #858585;

  user-select: none;

  cursor: pointer;

  background-color: $background-items;

  &:hover {
    svg path {
      fill: #00C2FD;
    }

    svg circle {
      stroke: #00C2FD;
    }
  }

  &.rightArrow {
    transform: rotate(180deg);
  }

  &.arrowsIconBtnDisabled {
    background-color: hsl(0, 0%, 10%);

    cursor: auto;

    svg path {
      fill:  hsl(0, 0%, 20%);
    }

    &:hover {
      svg path {
        fill:  hsl(0, 0%, 20%);
      }
    }
  }
}

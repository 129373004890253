@import '../../../../globalStyles/global.module.scss';

.imgConditional {
	width: 22px;
	height: 22px;

	border-radius: $standard-border-radius;

	margin-right: 10px;
}

.tableText {

	width: 100%;
	height: 35px;

	display: flex;
	gap: 10px;

	font-family: 'Inter';
	font-style: normal;
	font-size: 18px;
	line-height: 21px;

	color: #cdcdcd;
}

.row{
	width: calc(100% - 44px);
	word-wrap: break-word;

	cursor: pointer;
	user-select: none;
}

.modal {
	display: flex;
	align-items: center;
	justify-content: center;
}

.modalContainer {
	width: 30%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	gap: 20px;
	padding: 18px 24px;
	border-radius: $standard-border-radius;

	font-size: 20px;

	background-color: $background-items;
	color: #ccc;
	border: 1px solid #858585;
}

.modalContent {
	font-size: 22px;
	color: white;
	margin: 4px 0;
	text-align: center;
}

.checkInput {
	width: calc(100% - 22px);

	padding: 10px;

	border: 1px solid #858585;
	border-radius: $standard-border-radius;

	font-size: 20px;

	background: inherit;
	color: white;

	&:focus {
		outline: none;
	}
}

.modalButtons {
	width: 100%;

	display: flex;
	justify-content: space-between;
}

.closeIcon {
	width: 22px;
	height: 22px;
	cursor: pointer;

	&:hover {
		svg path {
			fill: #00C2FD;
		}

		svg circle {
			stroke: #00C2FD;
		}
	}
}

.modalDeleteButton {
	width: 40%;
	height: 40px;

	font-size: 20px;

	border: 2px solid #00c2fd;
	border-radius: $standard-border-radius;

	color: #00c2fd;
	background: transparent;

	&:disabled,
	&:disabled:hover {
		border: none;

		color: rgba(108, 108, 108, 0.8);
		background: transparent;
	}

	&:hover:not(:disabled) {
		cursor: pointer;

		color: $background-items;
		background: #00c2fd;
	}
}

.modalButton {
	width: 40%;
	height: 40px;
}

.closeIcon {
	cursor: pointer;
}

.box{
  width: calc(100% - 40px);
  height: 100%;

  display: flex;
  justify-content: space-between;

  overflow-y: auto;

  padding: 20px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #D2D2D2;
  }
}

.imgStyle {
  max-height: 20vh;
  max-width: 10vw;

  border-radius: 10px;
  padding-bottom: 10px;

  background: rgba(63, 63, 63, 0.3);
  object-fit: contain; /* or object-fit: cover; */
}


.infoPerson{
  width: 100%;
  height: 55px;

  display: flex;
  flex-direction: column;

  color: #CDCDCD;
}

.titleStyle{
  font-family: 'Inter';
  font-style: normal;
  font-size: 18px;
}

.valueStyle{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;

  padding-bottom: 2px;
}

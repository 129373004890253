.popupContainer {
    display: flex;
    flex-direction: column;
}

.navigateButton {
    padding: 5px;
    margin: 5px;

    position: absolute;
    bottom: 0;
    left: 0;

    border: none;

    font-size: 14px;

    background: none;
    color: white;

    cursor: pointer;

    &:hover {
        color: #00C2FD;
    }
}

.listItemIcon{
    width: 14px;
    height: 14px;
  
    border-radius: 50%;
  
    background: #F6BA1B;
  }
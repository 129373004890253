@import '@globalStyles/global.module.scss';

.content {
  $rowWidth: calc(25% - 10px);
  width: calc(100% - $mr - $ml);
  height: calc(100% - $mt - $mb); // Высота дочернего элемента с учетом отступов

  display: grid;
  grid-template-rows: $rowWidth $rowWidth $rowWidth $rowWidth;
  grid-row-gap: 10px;

  border: none;

  margin: $mt $mr $mb $ml; // Отступы от родительского элемента
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .modalContainer {
    width: 600px;
    height: 300px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    border-radius: $standard-border-radius;

    font-size: 35px;

    background-color: $background-items;
    color: #F6BA1B;
    border: 1px solid #858585;

    .closeIcon {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;

      &:hover {
        svg path {
          stroke: #00C2FD;
        }
      }
    }
  }
}

@import '../../globalStyles/global.module.scss';

.parent {
	min-height: 130px;
	width: 85px;

	position: absolute;
	bottom: calc($mb + 22px);
	z-index: 1002;
}

.container {
	height: 130px;
	width: 100%;

	display: flex;
	align-items: center;

	position: absolute;
	bottom: 0;

	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	border: 1px solid #858585;
	border-left: none;

	background-color: $background-items;

	cursor: pointer;
}

.bricks {
	position: absolute;
	bottom: 134px;
	margin: $margin-menu;

	opacity: 0;
	visibility: hidden;

	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
	transform: translateY(70%);

	&.show {
		display: flex;
		flex-direction: column;

		opacity: 1;
		visibility: visible;

		transform: translateY(0);
	}
}

.brick {
	margin-top: 5px;
}

.logoMenu {
	position: absolute;
	
	padding: 0 10px;
	margin-left: $margin-menu;
}

@import '../../../globalStyles/global.module.scss';

.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.tempZone {
    width: 55%;
    height: 55%;

    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;

    transform: translate(-44%, -56%);

    .areaCanvas {
        position: absolute;
        border: 1px solid black;

        z-index: 100;
    }
}

.mouseControl {
    width: 100%;
    height: 100%;

    position: absolute;
    left: 0;
    top: 0;

    z-index: 10000;
}

.listArea {
    width: 20%;
    height: 55%;

    position: absolute;
    right: 10px;
    top: 50%;

    transform: translate(10%, -56%);

    border-radius: 10px;
    border: 1px solid #858585;

    color: #D2D2D2;
    background: #272727;
    z-index: 1;
}

.tempButton {
	position: absolute;
	top: 35px;
	left: 50%;
	z-index: 1;

    text-align: center;

	transform: translate(-50%, -50%);

	padding: 8px 16px;

	border-radius: $standard-border-radius;
	
	font-family: 'Inter', sans-serif;
	font-size: 20px;

	user-select: none;

	background-color: #00c2fd;
	color: #fff;
}

.warnText {
    margin-top: 5px;

    font-size: 14px;
}

.colorLine {
    height: 30px; 

    display: flex;
    justify-content: space-between;
    align-items: center;
    
    position: absolute;
    bottom: 19%;
    right: 50%;
    transform: translateX(56%);

    border-radius: 10px;
    border: 1px solid #858585;

    color: #D2D2D2;
    background: #272727; 
}

.colorBlock {
    width: 80%;
    height: 20px;
    
    position: absolute;
    right: 50%;
    transform: translate(50%);

    opacity: 0.7;
    background: linear-gradient(to right, #0000FF, #0088FF, #00FFFF, #00FF88, #00FF00, #88FF00, #FFFF00, #FF8800, #FF0000);
}
@import "../../globalStyles/global.module";

.set {
  margin: $mt $mr $mb $ml; // Отступы от родительского элемента
  width: calc(100% - $ml - $mr); // Ширина дочернего элемента с учетом отступов
  height: calc(100% - $mt - $mb); // Высота дочернего элемента с учетом отступов

  display: flex;

  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;

  > div {
    width: 100%;
  }
}

.allCards {
  display: flex;
}

.cards {
  width: calc(75.1% - 10px);

  display: grid;
  grid-template-columns: repeat(2, calc(50% - 5px));
  grid-template-rows: repeat(2, calc(50% - 5px));
  grid-gap: 10px 10px;
}
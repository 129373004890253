@import '@globalStyles/global.module.scss';

.main {
  width: 100%;
  height: 100%;


  position: absolute;
  z-index: 1;

  background: #272727;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.buttonPos {
  position: fixed;
  margin: $margin-menu;
  z-index: 2;
}

.toastMessage {
	border: 1px solid #858585;
	border-radius: $standard-border-radius;

	color: #D2D2D2;
    background: $background-items;

	--toastify-icon-color-success: #00C2FD;
	--toastify-color-progress-success: #00C2FD;
}

.toastError {
	border: 1px solid #858585;
	border-radius: $standard-border-radius;

	color: #D2D2D2;
    background: $background-items;
}

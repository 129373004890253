@import '../../../../globalStyles/global.module.scss';

.placeItem {
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 10px;
	margin: 2px 0;

	border-radius: 10px;
	border: 1px solid $background-items;

	cursor: pointer;

	&.show {
		border: 1px solid #00C2FD;

		color: #00C2FD;
	}

	&:hover {
		.navigateItem {
			display: block;
		}

		color: #00C2FD;

		border: 1px solid #00C2FD;
	}
}

.navigateItem {
	display: none;

	font-size: 14px;

	&:hover {
		color: #00c2fd;
	}
}

.titleList {
	text-align: center;
	padding: 0 10px 10px 10px;
	margin: 2px 0;
}
@import '../../../globalStyles/global.module.scss';

.mainButton {
	width: 50px;
	height: 50px;

	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 10px;
	border: 1px solid #858585;

	user-select: none;

	cursor: pointer;

	background-color: $background-items;

	&:hover {
		svg path {
			fill: #00C2FD;
		}

		svg circle {
			stroke: #00C2FD;
		}

		svg polyline {
			stroke: #00C2FD;
		}
	}
}

.mainButtonDisabled {
	width: 50px;
	height: 50px;

	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 10px;
	border: 1px solid hsl(0, 0%, 40%);

	user-select: none;

	cursor: not-allowed;

	background-color: hsl(0, 0%, 10%);
}

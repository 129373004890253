@import '../../../globalStyles/global.module.scss';

.box{
  width: 33%;
  height: calc(100% - 15px);

  display: flex;
  flex-direction: column;

  border: 1px solid #858585;
  border-radius: $standard-border-radius;

  background-color: $background-items;
  box-sizing: border-box;
}

.content{
  width: 100%;
  height: calc(100% - 20px);


  display: flex;
  flex-direction: column;

  overflow-y: auto;
  overflow-x: auto;
}

.content::-webkit-scrollbar{
  width: 6px
}

.content::-webkit-scrollbar-thumb {
  background-color:  #ccc;
  border-radius: 3px
}

.titleStyle{
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  color: #CDCDCD;
}
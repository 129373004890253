.container {
    width: calc(100% - 30px);
    height: 100%;
    
	display: flex;
	justify-content: center;
	align-items: center;

	padding: 0 15px;

	user-select: none;
}
@import '@globalStyles/global.module.scss';

.placeItem {
	height: 25px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 10px;
	margin: 2px 0;

	border-radius: 10px;
	border: 1px solid $background-items;

	cursor: pointer;

	&.show {
		border: 1px solid #00C2FD;
		color: #00C2FD;
	}

	&:hover {
		.iconSet {
			display: flex;
		}

		border: 1px solid #00C2FD;
		color: #00C2FD;
	}
}

.patrolHeader {
	height: 26px; 
	display: flex;
	justify-content: space-between; 
	padding: 10px;
}

.analiticaStyle {
	display: none;
}

.addPreset {
	display: flex;

	padding: 10px;

	cursor: pointer;

	&:hover {
		color: #00C2FD;

		.iconAdd {
			svg path {
				fill: #00C2FD;
			}
		}
	}
}

.handlerPatrol {
	padding: 0 10px;

	cursor: pointer;

	&:hover {
		color: #00C2FD;
	}
}

.iconAdd {
	display: flex;
	
	margin-right: 10px;

	cursor: pointer;

	&:hover {
		svg path {
			fill: #00C2FD;
		}
	}
}

.iconPatrol {
	display: flex;
	
	margin-right: 10px;

	cursor: pointer;

	&:hover {
		svg path {
			stroke: #00C2FD;
		}
	}
}

.iconSet {
	display: none;

	cursor: pointer;

	&:hover {
		svg path {
			fill: #00C2FD;
		}
	}
}

.modal {
	display: flex;
	align-items: center;
	justify-content: center;

	border: none !important;
}

.modalContent {
	width: 40%;
}
@import '../../../../../globalStyles/global.module';

.modalContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	gap: 20px;
	padding: 18px 24px;
	border-radius: $standard-border-radius;
	border: 1px solid #858585;

	font-size: 20px;

	background-color: $background-items;
	color: #ccc;
}

.modalContent {
	text-align: center;

	margin: 4px 0;

	font-size: 22px;
	color: white;
}

.search {
	width: calc(100% - 10px);
	
	padding: 5px;

	display: flex;
	align-items: center;

	border: 1px solid #858585;
	border-radius: $standard-border-radius;

	&:hover {
        border: 1px solid #00C2FD;
    }

	&:focus-within {
		border: 1px solid #00C2FD;
	}
}

.searchInput {
	width: 100%;

	padding: 5px;
	
	border: none;

	font-size: 18px;
	font-family: 'Inter';

	background: inherit;
	color: white;

    &:focus {
        outline: none;
    }
}
$primary-color: #00c2fd;
@import '../../../globalStyles/global.module.scss';

.wrapper {
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	font-family: 'Inter';
}



.card {
	width: 729px;
	height: 657px;

	display: flex;

	flex-direction: column;
	align-items: center;

	background: rgba(58, 58, 58, 0.7);
	backdrop-filter: blur(17px);

	border-radius: 14px;
}

.textFieldCard {
	max-height: 182px;

	height: 110px;

	display: flex;

	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.textfieldStyle {
	width: 100%;
	height: 60px;

	font-family: 'Inter';
	font-size: 20px;

	border-radius: 8px;
	border: none;
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(50px);

	//margin: 100px 0;
}

.error{
	color: red;
}

.logo {
	width: max-content;
	height: max-content;

	display: flex;
	justify-content: center;

	align-items: flex-start;

	padding: 30px 0;
}

.formikCard {
	width: 90%;
	height: 80%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 50px;

	padding: 20px;
}

// input:-webkit-autofill {
// 	-webkit-box-shadow: 0 0 0 40px #4c4c4c inset;
// 	-webkit-text-fill-color: #000000 !important;
// 	font-size: 24px !important;
// 	border-radius: 0;
// }

.downCardButtons {
	display: flex;

	justify-content: space-between;
	align-items: flex-end;
	align-content: center;
}

.btnSubmit {
	width: 100%;
	height: 60px;

	font-family: 'Inter';
	font-size: 20px;
	border: 2px solid #00c2fd;
	border-radius: $standard-border-radius;

	color: #000000;
	background-color: $primary-color;

	cursor: pointer;
}

.contactUS {
	width: 35%;
	height: 80%;

	display: flex;
	align-items: center;

	font-family: 'Inter';
	font-size: 22px;
	color: #CDCDCD;

	cursor: pointer;
}

@media (max-width: 1550px) {
	.card {
		width: 40%;
		height: 70%;
	}
	.textFieldCard {
		height: 60%;
	}
	.textfieldStyle {
		width: 100%;
		// height: 45%;
	}

	.formikCard {
		width: 90%;
		height: 80%;
	}
	.downCardButtons {
		height: 25%;
	}
	//.btnSubmit {
	//	width: 100%;
	//	height: 61px;
	//}

	.contactUS {
		width: 35%;
		height: 80%;
	}
}
@media (max-width: 865px) {
	.card {
		width: 70%;
	}
}
@media (max-width: 385px) {
	.textfieldStyle {
		font-size: 16px;
	}

	.btnSubmit {
		font-size: 16px;
	}

	.contactUS {
		font-size: 16px;
	}
}

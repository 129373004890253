.setIcons {
  margin-right: 10px;

  display: flex;
  justify-content: right;
  gap: 10px;

  cursor: pointer;
}

.icon {
  height: 46.4px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.excel{
  width: 35px;

  display: flex;
  justify-content: center;

  padding: 8px;
  border: none;
  cursor: pointer;

  font-size: 18px;
  color: #D2D2D2;
  background-color: transparent;
}
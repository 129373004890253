@import '../../../../globalStyles/global.module.scss';

.cardsRow{
  width: calc(100% - 60px - 45px);
  height: 100%;

  display: flex;
}

.smallRow{
  width: 450px;
  height: 100%;

  display: flex;
}


.boxRow{
  width: 700px;
  height: 100%;

  display: flex;
  gap: 10px;
}

.mainButton {
  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  border: 1px solid #858585;

  user-select: none;

  cursor: pointer;

  background-color: rgba(31, 31, 31, 1);

  &:hover {
    svg path {
      fill: #00C2FD;
    }

    svg circle {
      stroke: #00C2FD;
    }
  }
}

.mainButtonDisabled {
  width: 50px;
  height: 50px;

  margin: 10px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  border: 1px solid hsl(0, 0%, 40%);

  user-select: none;

  background-color: hsl(0, 0%, 10%);

  svg path {
    fill:  hsl(0, 0%, 20%);
  }
}


.buttonPos{
  margin-left: -$ml + 26px;
  z-index: 2;
  display: flex;

}

.content {

  position: relative;

  display: flex;
  gap: 10px;
  align-content: center;
  align-items: center;

  border-radius: 10px;
  overflow-x: auto;
  flex-wrap: nowrap;


  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #D2D2D2;
  }
}
@import '../../../../../globalStyles/global.module.scss';

.tabs {
  font-family: 'HalvarBreit';
  font-size: 18px;
  color: #BEBEBE;
}
.modal{
  width: 50%;
  height: max-content;
  max-height: 85%;
  min-height: 200px;

  display: flex;
  flex-direction: column;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: #272727;
  border: 1px solid #6C6C6C;
  border-radius: $standard-border-radius;

  padding:  20px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #D2D2D2;
  }
}
@import '../../../../globalStyles/global.module.scss';

.modalContainer {
	width: 30%;

	position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	gap: 20px;
	padding: 18px 24px;
	border-radius: $standard-border-radius;
	border: 1px solid #858585;

	font-size: 20px;

	background-color: $background-items;
	color: #ccc;
}

.search {
	width: calc(100% - 10px);
	
	padding: 5px;

	display: flex;
	align-items: center;

	border: 1px solid #858585;
	border-radius: $standard-border-radius;

	&:hover {
		border: 1px solid #00c2fd;
	}

	&:focus-within {
		border: 1px solid #00c2fd;
	}
}

.searchInput {
	width: 100%;

	padding: 5px;

	border: none;

	font-size: 18px;

	background: inherit;
	color: white;

	cursor: pointer;

	&:focus {
		outline: none;
	}
}

@import '../../../globalStyles/global.module.scss';

.box {
  width: calc(33% - 2px);
  height: 100%;

  display: flex;

  border-radius: $standard-border-radius;

  background-color: $background-items;
}

.card {
  width: 100%;

  position: relative;

  display: flex;
  flex-direction: column;

  padding: 20px;
  gap: 10px;

  border: 1px solid #858585;
  border-radius: $standard-border-radius;
}
.empty{
  width: 100%;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  color: #CDCDCD;
  font-size: 18px;
}
.header {
  width: 100%;
  height: 60px;

  display: flex;
  justify-content: space-between;
  align-content: center;

  color: #CDCDCD;
  font-size: 20px;
}

.containerButtons {
  width: 99%;
  margin: 1px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.buttonPeriod {
  width: calc(100% - 20px);
  height: 40px;

  padding: 0 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 1px solid #858585;
  border-radius: $standard-border-radius;

  color: #cfcfcf;

  font-size: 20px;

  &:hover, &.active {
    border: 1px solid #00C2FD;
    color: #00C2FD;
  }

  &:focus-within {
    border: 1px solid #00C2FD;
  }

  cursor: pointer;
  user-select: none;
}

.chartsBox {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  gap: 10px;

  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #D2D2D2;
  }

  .chartsTitle {
    width: 100%;

    color: #CDCDCD;
    font-size: 20px;

    display: flex;
    align-items: flex-start;

    padding-left: 5px;
  }
}

.charts {
  width: 99%;
  height: calc(40%);
}

.center {
  width: calc(100% - 40px);

  position: absolute;
  bottom: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: #CDCDCD;
}

.arrowsIconBtn {
  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  border: 1px solid #858585;

  user-select: none;

  cursor: pointer;

  background-color: $background-items;

  &:hover {
    svg path {
      fill: #00C2FD;
    }

    svg circle {
      stroke: #00C2FD;
    }
  }

  margin: 10px 0;
}

.arrowsIconBtnDisabled {
  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  border: 1px solid hsl(0, 0%, 40%);

  user-select: none;

  background-color: hsl(0, 0%, 10%);

  margin: 10px 0;

  svg path {
    fill:  hsl(0, 0%, 20%);
  }
}

.rightArrow {
  transform: rotate(180deg);
}